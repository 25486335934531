<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </b-col>
          <b-col cols="12"        
          class="d-flex align-items-end justify-content-end mb-1 mt-1 mb-md-0">
            <b-button
    
              variant="primary"
              class="mr-1"
              @click="getData"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
      data-testid="posts-table"
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="PostsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(type)="data">
          <span v-if="data.item.type == 1">Articles</span>
          <span v-else>News</span>
        </template>
        <template #cell(display_in_home)="data">  
  <b-form-group class="mb-0">  
    
    
  <b-form-checkbox v-model="data.item.display_in_home" 
  @change="storeRowId(data.item.id, data.item.display_in_home)" 
  switch>  
   
  </b-form-checkbox>  

  </b-form-group>  
</template> 
        <!-- Column: Status -->
        <template #cell(actions)="data">
          <a
            target="_blank"
            :href="`https://lpcentre.com/articles/${data.item.slug}`"
          >
            <feather-icon icon="LinkIcon" />
          </a>
          <a>
            <router-link
              :to="`/update-article/${data.item.id}`"
              style="margin: 0px 12px"
              :data-testid="`edit-item-${data.item.id}`"

            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
          <a
            v-if="$can('delete', 'post')"
            role="button"
            @click="DeleteCourse(data.item.id)"
          >
            <feather-icon icon="TrashIcon" 
            :data-testid="`delete-item-${data.item.id}`"

            />
          </a>
        </template>

        <!-- Column: Actions -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BFormGroup,
  BFormCheckbox

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, reactive, computed,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BFormGroup,
    BFormCheckbox

  },
  setup() {
    const PostsList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(25)
    const totalCategories = ref(0)
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
    const currentPage = ref(1)
    const comingSoonData = ref({}); // Using ref to create a reactive object  
    const courseID = ref("")

  const storeRowId = (id, comingSoonValue) => {  
  // Store the value for the specific row ID  
  comingSoonData.value=  comingSoonValue ? 1 : 0; // Convert to 0 or 1    
  courseID.value = id
  console.log(comingSoonData.value)

  console.log(`Row ID: ${id}, Coming Soon Value: ${comingSoonValue}`);  
};  


const getData=()=>{
      const formData = new FormData()
          formData.append('display_in_home', comingSoonData.value)
          // formData.append('id', courseID.value)
console.log(formData)
store
       .dispatch('courses/changeBlogShow',{id: courseID.value , formData})
       .then((response) => {
        GetAllPosts()
         Vue.swal({
           title: " Changes saved successfuly ",
           icon: "success",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: false,
         });
       })
       .catch((error) => {
         Vue.swal({
           title: "",
           text: `${error.response.data.message}`,
           icon: "error",
           confirmButtonText: "Ok",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: false,
         });
       });
}
    const GetAllPosts = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }

      store
        .dispatch('blog/getAllBlog', {
          sort: data,
          'filter[search]': searchQuery.value,
          per_page: perPage.value,
          page: currentPage.value,
       
        })
        .then(response => {
          PostsList.value = response.data.data.map(item => ({  
    ...item,  
    display_in_home: item.display_in_home === 1? true: false  
}));
          totalCategories.value = response.data.meta.total
        
        })
    }
    const refCourseListTable = ref()
    watch([currentPage, perPage, sort, searchQuery], () => {
      GetAllPosts()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // }
    GetAllPosts()
    const DeleteCourse = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('blog/DeleteBlog', id)
          .then(response => {
            GetAllPosts()
            Vue.swal({
              title: 'Post Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', lanel: 'ID', sortable: true },
      { key: 'title', label: 'Title', sortable: true },
      { key: 'type', label: 'Type', sortable: true },
      { key: 'slug', label: 'In Link Name', sortable: true },
      { key: 'since', label: 'Since', sortable: true },
      { key: 'display_in_home', label: 'Display In Home', sortable: true },

      { key: 'actions' },
    ]

    return {
      tableColumns,
      PostsList,
      searchQuery,
      DeleteCourse,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      refCourseListTable,
      dataMeta,
      SortArray,
      sort,
      getData,
      comingSoonData, // return this if you need to use it elsewhere  
      storeRowId,
      courseID
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
